var require = {
    baseUrl: '/',
    waitSeconds: 20,
    map: {
        '*': {
            knockout: 'lib/knockout-extra/main',
            moment: 'lib/moment-extra',
        },
    },
};
